import React, { useEffect, useRef } from "react";
import { Language } from "types/MultiLingualString";
import getLanguageObject from "helpers/language";
import { useHistory } from "react-router-dom";
import { LoadAllOrganisations, LoadPagedUsers } from 'store/user/user.actions';
import { Organisation, OrganisationUser } from 'types/Organisation';
import { StyledHeader } from 'sections/Admin/AdminAllocatorQuestionsList/AdminAllocatorQuestionsList.components';
import BreadCrumbs from 'components/BreadCrumbs/BreadCrumbs';
import { AdminUserManagementContainer, AdminUserManagementListContainer, Column, PaginantionContainer, PaginationButton, SingleAdminUserManagementContainer } from 'sections/Admin/AdminUserManagementList/AdminUserManagementList.components';
import { SelectField } from 'containers/InputField/InputField';
import Routes from 'routes/Routes.types';
import { SortArrowDownIcon } from 'components/Icons/arrowDown';
import SortArrowUpIcon from 'components/Icons/arrowUp';
import apiRequest from 'helpers/api';
import { handleApiError } from 'helpers/errorHandler';
import { UserErrorTypes } from 'store/user/user.errors';
import store from 'store/store';
import { Domain } from "../../../types/Domain";
import { CSVLink } from 'react-csv';
import PrintIcon from 'components/Icons/print';
import Button from 'components/Button/Button';

interface AdminUserManagementListProps {
    allUsers: OrganisationUser[];
    allUsersTotalCount: number;
    allOrganisations: Organisation[];
    currentLanguage: Language;
    loadPagedUsers: LoadPagedUsers;
    loadAllOrganisations: LoadAllOrganisations;
}

export const AdminUserManagementList: React.FC<AdminUserManagementListProps> = ({
    allUsers,
    allUsersTotalCount,
    allOrganisations,
    currentLanguage,
    loadPagedUsers,
    loadAllOrganisations
}) => {
    const history = useHistory();
    const state = store.getState();

    const lang = getLanguageObject(currentLanguage);

    const [skip, setSkip] = React.useState(0);
    const [take, setTake] = React.useState(20);
    const [sorting, setSorting] = React.useState<"FirstName" | "LastName" | "EmailAddress" | "OrganisationRoleName">("FirstName");
    const [sortingOrder, setSortingOrder] = React.useState<"ASC" | "DESC">("ASC");

    const pageSizeOptions = [10, 20, 50, 100];
    const [selectedOrganisation, setSelectedOrganisation] = React.useState<Organisation | null>(null);

    const [csvData, setCsvData] = React.useState<any[]>([]);
    const [csvDataLoading, setCsvDataLoading] = React.useState(false);
    const csvInstance = useRef<any | null>(null);

    useEffect(() => {
        loadAllOrganisations();
    }, []);

    useEffect(() => {
        if (csvData.length > 0 && csvDataLoading) {
            setTimeout(() => {
                csvInstance.current.link.dataset.interception = 'off'
                csvInstance.current.link.click();
                setCsvDataLoading(false);
            });
        }
    }, [csvData]);

    useEffect(() => {
        loadPagedUsers(skip, take, sorting, sortingOrder, selectedOrganisation?.id);
    }, [skip, take, selectedOrganisation, sorting, sortingOrder]);


    const toggleSortingOrder = (sortColumn: "FirstName" | "LastName" | "EmailAddress" | "OrganisationRoleName") => {
        if (sorting === sortColumn) {
            setSortingOrder(sortingOrder === "ASC" ? "DESC" : "ASC");
        } else {
            setSortingOrder("ASC");
            setSorting(sortColumn);
        }
    }

    const navigate = (id: string) => {
        history.push(Routes.AdminUserManagementDetailRoute + id);
    }

    const mabyRenderSortingIcon = (column: "FirstName" | "LastName" | "EmailAddress" | "OrganisationRoleName") => {
        if (sorting === column) {
            return sortingOrder === "DESC" ? <SortArrowUpIcon color="#000" /> : <SortArrowDownIcon color="#000" />
        }
        return null;
    }

    const getCSVData = async () => {
        let endpoint = `admin/users?skip=0&take=0&orderByField=${sorting}&sortOrder=${sortingOrder}`;
        if (selectedOrganisation)
            endpoint = `${endpoint}&organisationId=${selectedOrganisation.id}`;
        try {
            apiRequest(endpoint, 'GET', state.user.jwt, undefined, {
                isAccountApi: true,
                currentOrganisation: state.user.currentOrganisation?.id
            }).then((result) => {
                const csvData = result.data.map((user: any) => {
                    return {
                        firstName: user.firstName,
                        lastName: user.lastName,
                        emailAdress: user.emailAdress,
                        roleName: user.roleName,
                        organisations: renderOrganisationNames(user, true)
                    }
                });
                setCsvDataLoading(true);
                setCsvData(csvData);
            }
            );
        } catch (error) {
            setCsvDataLoading(false);
            //TODO: add Admin domain to error handler
            handleApiError(Domain.User, UserErrorTypes.loadPagedUserRequest, store.getState().language.currentLanguage);
            return [];
        }
    }

    const renderOrganisationNames = (user: OrganisationUser, fullString?: boolean) => {
        let names = user?.roleName === 'Administrator' ? lang.allOrganisations : (user?.organisations ?? []).map(x => x.name).join(', ');
        const cutOffLength = 40;

        if (names.length > cutOffLength && !fullString)
            names = names.substring(0, cutOffLength) + '...'

        return names;
    }

    return (
        <AdminUserManagementListContainer>
            <StyledHeader style={{ backgroundColor: "#eff4f9", gridTemplateColumns: "1fr 260px 110px 110px" }}>
                <BreadCrumbs title={`${lang.manageUsers}(${allUsersTotalCount})`} paths={[]} />
                <>
                    {allOrganisations && allOrganisations.length > 0 && <SelectField
                        fullWidth
                        width={250}
                        label={lang.filterOrganisation}
                        value={selectedOrganisation?.id ?? ""}
                        onChange={(e) => {
                            setSkip(0);
                            setSelectedOrganisation(allOrganisations.find((org) => org.id === e.target.value) ?? null);
                        }}
                        options={
                            <>
                                <option value={""}>{lang.showAll}</option>
                                {allOrganisations.map((org) => (
                                    <option key={org.id} value={org.id}>
                                        {org.name}
                                    </option>
                                ))}
                            </>
                        }
                    />}
                    <SelectField
                        fullWidth
                        width={100}
                        label={lang.paginationTake}
                        value={take}
                        onChange={(e) => {
                            setTake(parseInt(e.target.value));
                        }}
                        options={
                            <>
                                {pageSizeOptions.map((option) => (
                                    <option key={option} value={option}>
                                        {option}
                                    </option>
                                ))}
                            </>
                        }
                    />

                    <Button
                        text='Export'
                        style={{
                            width: '100px',
                            borderRadius: '5px',
                            border: '2px solid #3b4b63',
                            color: '#000',
                            backgroundColor: '#fff',
                            minWidth: '100px',
                            marginTop: '22px',
                            height: '46px'
                        }}
                        onClick={() => {
                            getCSVData();
                        }} />

                    <CSVLink
                        data={csvData}
                        asyncOnClick={true}
                        ref={csvInstance}
                        data-interception='off'
                        headers={[
                            { label: lang.firstName, key: 'firstName' },
                            { label: lang.lastName, key: 'lastName' },
                            { label: lang.usernameOrEmail, key: 'emailAdress' },
                            { label: lang.userRole, key: 'roleName' },
                            { label: lang.organisations, key: 'organisations' }
                        ]}
                        filename={`${lang.userExportFileName}.csv`}>
                    </CSVLink>
                </>

            </StyledHeader>
            <AdminUserManagementContainer>
                <SingleAdminUserManagementContainer>
                    <Column header onClick={() => { toggleSortingOrder('FirstName') }}>{lang.firstName}{mabyRenderSortingIcon('FirstName')}</Column>
                    <Column header onClick={() => { toggleSortingOrder('LastName') }}>{lang.lastName}{mabyRenderSortingIcon('LastName')}</Column>
                    <Column header onClick={() => { toggleSortingOrder('EmailAddress') }}>{lang.usernameOrEmail}{mabyRenderSortingIcon('EmailAddress')}</Column>
                    <Column>{lang.organisations}</Column>
                    <Column header onClick={() => { toggleSortingOrder('OrganisationRoleName') }}>{lang.userRole}{mabyRenderSortingIcon('OrganisationRoleName')}</Column>
                </SingleAdminUserManagementContainer>
                {(allUsers ?? []).map((user) => (
                    <SingleAdminUserManagementContainer key={user.id} onClick={() => navigate(user.id)}>
                        <Column>{user.firstName}</Column>
                        <Column>{user.lastName}</Column>
                        <Column>{user.emailAdress}</Column>
                        <Column title={renderOrganisationNames(user, true)}>{renderOrganisationNames(user)}</Column>
                        <Column>{user.roleName}</Column>
                    </SingleAdminUserManagementContainer>
                ))}

            </AdminUserManagementContainer>
            <PaginantionContainer>
                <PaginationButton
                    disabled={skip === 0}
                    onClick={() => { if (skip > 0) setSkip(0) }}>
                    {'<<'}
                </PaginationButton>
                <PaginationButton
                    disabled={skip === 0}
                    onClick={() => { if (skip > 0) setSkip(skip - take) }}>
                    {'<'}
                </PaginationButton>
                {Array.from({ length: Math.ceil(allUsersTotalCount / take) }, (_, index) => {
                    if (
                        (index >= skip / take - 2 && index <= skip / take + 2)
                    ) {
                        return (
                            <PaginationButton
                                currentPage={skip === index * take}
                                key={index}
                                onClick={() => setSkip(index * take)}
                                style={{ filter: skip === index * take ? "active" : "" }}
                            >
                                {(index + 1).toString()}
                            </PaginationButton>
                        );
                    }
                    return null;
                })}
                <PaginationButton
                    disabled={skip + take >= allUsersTotalCount}
                    onClick={() => { if (skip + take < allUsersTotalCount) setSkip(skip + take) }}>
                    {'>'}
                </PaginationButton>
                <PaginationButton
                    disabled={skip + take >= allUsersTotalCount}
                    onClick={() => { if (skip + take < allUsersTotalCount) setSkip(Math.floor(allUsersTotalCount / take) * take) }}>
                    {'>>'}
                </PaginationButton>
            </PaginantionContainer>
        </AdminUserManagementListContainer>
    );
}
