import getLanguageObject from 'helpers/language';
import React from 'react';
import { BlocksGrid, DocumentsBlocks, Header } from './DocumentsBlocksSection.components';
import DocumentsBlockSectionProps, { DocumentsBlockProps } from 'sections/DocumentsBlocksSection/DocumentsBlocksSection.types';
import compensationPolicy from '../../assets/pdfs/STYR Beloningsbeleid data NL 2025.xlsx';
import benchmark from '../../assets/pdfs/Styr Benchmark NL 2025.pdf';
import objectionProcedure from '../../assets/pdfs/Bezwaarprocedure.docx';
import confirmationLetter from '../../assets/pdfs/Bevestigingsbrief indeling.docx';
import faqJobMatrixImplementation from '../../assets/pdfs/FAQ Implementatie Job Matrix.docx';
import templateFAQStyrModelNL from '../../assets/pdfs/FAQ Styr model NL.docx';
import templateJobMatrixMaintenance from '../../assets/pdfs/Onderhoudsprocedure Job Matrix.docx';
import faqRewardPolicy from '../../assets/pdfs/FAQ Nieuw Beloningsbeleid.docx';
import templateAgreementCompensation from '../../assets/pdfs/Instemmingsaanvraag Beloningsbeleid NL.docx';
import templateFunctionAprisal from '../../assets/pdfs/Instemmingsaanvraag Functiewaardering NL.docx';
import templateFAQStyrModelEN from '../../assets/pdfs/FAQ Styr model EN.docx';

// Template Bezwaarprocedure.docx

import { BlocksGridContainer, Container } from 'layouts/MainLayout/MainLayout.components';
import BreadCrumbs from 'components/BreadCrumbs/BreadCrumbs';
import { Language } from 'types/MultiLingualString';
import { getNoExcessElements } from 'helpers/RequireAuth';

enum DocumentCategory {
    General,
    StyrModel,
    Implementation,
    Rewards,
    ImplementationRewards,
    Maintenance
}

interface Block extends DocumentsBlockProps {
    show: boolean;
    languages: Language[];
    blocks?: Block[];
    documentCategory: DocumentCategory;
}

const DocumentsBlocksSection: React.FC<DocumentsBlockSectionProps> = ({
    isSharedLink,
    currentLanguage,
    currentOrganisation }) => {
    let lang = getLanguageObject(currentLanguage);

    if (isSharedLink) {
        return getNoExcessElements(lang);
    }

    const documentHeaderText = lang.documentOverview;

    let filterLanguage = currentLanguage;
    if (currentLanguage !== 'nl-NL') {
        lang = getLanguageObject('en-EN');
        filterLanguage = 'en-EN';
    }

    const blocks: Block[] = [
        // Reward Policy
        {
            title: lang.benchmark,
            image: require('../../assets/images/Styr_kennisbank v2.png'),
            navigateToSite: benchmark,
            show: true,
            languages: ['nl-NL'],
            documentCategory: DocumentCategory.Rewards
        },
        {
            title: lang.compensationPolicy,
            image: require('../../assets/images/Styr_kennisbank v2.png'),
            navigateToSite: compensationPolicy,
            show: true,
            languages: ['nl-NL'],
            documentCategory: DocumentCategory.Rewards
        },
        // Implementation Job Matrix
        {
            title: lang.faqJobMatrixImplementation,
            image: require('../../assets/images/Styr_kennisbank v2.png'),
            navigateToSite: faqJobMatrixImplementation,
            show: true,
            languages: ['nl-NL'],
            documentCategory: DocumentCategory.Implementation
        },
        {
            title: lang.templateFunctionAprisal,
            image: require('../../assets/images/Styr_kennisbank v2.png'),
            navigateToSite: templateFunctionAprisal,
            show: true,
            languages: ['nl-NL'],
            documentCategory: DocumentCategory.Implementation
        },
        {
            title: lang.confirmationLetter,
            image: require('../../assets/images/Styr_kennisbank v2.png'),
            navigateToSite: confirmationLetter,
            show: true,
            languages: ['nl-NL'],
            documentCategory: DocumentCategory.Implementation
        },
        {
            title: lang.objectionProcedure,
            image: require('../../assets/images/Styr_kennisbank v2.png'),
            navigateToSite: objectionProcedure,
            show: true,
            languages: ['nl-NL'],
            documentCategory: DocumentCategory.Implementation
        },
        {
            title: lang.templateMaintenanceMatrix,
            image: require('../../assets/images/Styr_kennisbank v2.png'),
            navigateToSite: templateJobMatrixMaintenance,
            show: true,
            languages: ['nl-NL'],
            documentCategory: DocumentCategory.Implementation
        },
        // Implementation Reward Policy
        {
            title: lang.faqNewRewardsPolicy,
            image: require('../../assets/images/Styr_kennisbank v2.png'),
            navigateToSite: faqRewardPolicy,
            show: true,
            languages: ['nl-NL'],
            documentCategory: DocumentCategory.ImplementationRewards
        },
        {
            title: lang.templateAgreementCompensation,
            image: require('../../assets/images/Styr_kennisbank v2.png'),
            navigateToSite: templateAgreementCompensation,
            show: true,
            languages: ['nl-NL'],
            documentCategory: DocumentCategory.ImplementationRewards
        },
        // Styr Model
        {
            title: lang.elearningStyrModel,
            image: require('../../assets/images/Styr_kennisbank v2.png'),
            navigateToSite: 'https://styr.nl/e-learning/',
            show: true,
            languages: ['nl-NL'],
            documentCategory: DocumentCategory.StyrModel
        },
        {
            title: lang.elearningStyrModel,
            image: require('../../assets/images/Styr_kennisbank v2.png'),
            navigateToSite: 'https://styr.nl/en/e-learning/',
            show: true,
            languages: ['en-EN'],
            documentCategory: DocumentCategory.StyrModel
        },
        {
            title: lang.faq,
            image: require('../../assets/images/Styr_kennisbank v2.png'),
            navigateToSite: templateFAQStyrModelNL,
            show: true,
            languages: ['nl-NL', 'nl-BE'],
            documentCategory: DocumentCategory.StyrModel
        },
        {
            title: lang.faq,
            image: require('../../assets/images/Styr_kennisbank v2.png'),
            navigateToSite: templateFAQStyrModelEN,
            show: true,
            languages: ['en-EN'],
            documentCategory: DocumentCategory.StyrModel
        },
    ]

    return (

        <Container>
            <Header>
                <BreadCrumbs
                    title={documentHeaderText}
                    paths={[
                    ]}
                />
            </Header>
            <BlocksGridContainer>
                <BlocksGrid
                    lang={lang}
                    header={lang.documentTypeRewardsdata}
                    closeable>
                    {blocks.filter(x => x.languages.includes(filterLanguage) && x.documentCategory === DocumentCategory.Rewards).map((item) => {
                        if (!item.show) return null;
                        return (<DocumentsBlocks
                            key={item.title}
                            title={item.title}
                            image={item.image}
                            navigateToSite={item.navigateToSite}
                            navigateToPage={item.navigateToPage}
                        />)
                    })}
                </BlocksGrid>
                <BlocksGrid
                    lang={lang}
                    header={lang.documentTypeImplementation}
                    closeable
                >
                    {blocks.filter(x => x.languages.includes(filterLanguage) && x.documentCategory === DocumentCategory.Implementation).map((item) => {
                        if (!item.show) return null;
                        return (<DocumentsBlocks
                            key={item.title}
                            title={item.title}
                            image={item.image}
                            navigateToSite={item.navigateToSite}
                            navigateToPage={item.navigateToPage}
                        />)
                    })}
                </BlocksGrid>
                <BlocksGrid
                    lang={lang}
                    header={lang.implementationRewardsPolicy}
                    closeable
                >
                    {blocks.filter(x => x.languages.includes(filterLanguage) && x.documentCategory === DocumentCategory.ImplementationRewards).map((item) => {
                        if (!item.show) return null;
                        return (<DocumentsBlocks
                            key={item.title}
                            title={item.title}
                            image={item.image}
                            navigateToSite={item.navigateToSite}
                            navigateToPage={item.navigateToPage}
                        />)
                    })}
                </BlocksGrid>
                <BlocksGrid
                    lang={lang}
                    header='Styr Dock'>
                    {blocks.filter(x => x.languages.includes(filterLanguage) && x.documentCategory === DocumentCategory.General).map((item) => {
                        if (!item.show) return null;
                        return (<DocumentsBlocks
                            key={item.title}
                            title={item.title}
                            image={item.image}
                            navigateToSite={item.navigateToSite}
                            navigateToPage={item.navigateToPage}
                        />)
                    })}
                </BlocksGrid>
                <BlocksGrid
                    lang={lang}
                    header={lang.documentTypeStyrModel}
                    closeable>
                    {blocks.filter(x => x.languages.includes(filterLanguage) && x.documentCategory === DocumentCategory.StyrModel).map((item) => {
                        if (!item.show) return null;
                        return (<DocumentsBlocks
                            key={item.title}
                            title={item.title}
                            image={item.image}
                            navigateToSite={item.navigateToSite}
                            navigateToPage={item.navigateToPage}
                        />)
                    })}
                </BlocksGrid>
            </BlocksGridContainer>
        </Container>
    );
};

export default DocumentsBlocksSection;
