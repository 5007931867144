import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Allocator from 'screens/Allocator/Allocator';
import Dashboard from 'screens/Dashboard/Dashboard';
import JobFamilyEditScreen from 'screens/JobFamilyEditScreen/JobFamilyEditScreen';
import JobFamilyMatrixScreen from 'screens/JobFamilyMatrixScreen/JobFamilyMatrixScreen';
import LatestUpdates from 'screens/LatestUpdates/LatestUpdates';
import LoginSharedLink from 'screens/LoginSharedLink/LoginSharedLink';
import ManageJobFamilyConnected from 'screens/ManageJobFamily/ManageJobFamily';
import Valuation from 'screens/ManageOrganisationValuation/ManageOrganisationValuation';
import ManageShareLinks from 'screens/ManageShareLinks/ManageShareLinks';
import JobMatrix from 'screens/Matrix/JobMatrix';
import JobMatrixPrint from 'screens/Matrix/JobMatrixPrint';
import TalentMatrix from 'screens/Matrix/TalentMatrix';
import TalentMatrixPrint from 'screens/Matrix/TalentMatrixPrint';
import Pdf from 'screens/Pdf/Pdf';
import Result from 'screens/Result/Result';
import Results from 'screens/Results/Results';
import StyrManual from 'screens/StyrManual/StyrManual';
import ManageJobMatrix from '../screens/ManageJobMatrix/ManageJobMatrix';
import ManageOrganisationDetails from '../screens/ManageOrganisationDetails/ManageOrganisationDetails';
import ManageOrganisationUsers from '../screens/ManageOrganisationUsers/ManageOrganisationUsers';
import Update from '../screens/Update/Update';
import Routes from './Routes.types';
import Admin from 'screens/Admin/Admin';
import AdminAllocatorQuestions from 'screens/Admin/AdminAllocatorQuestions/AdminAllocatorQuestions';
import AdminAllocatorValidatedProfiles from 'screens/Admin/AdminAllocatorValidatedProfiles/AdminAllocatorValidatedProfiles';
import AdminAllocatorQuestion from 'screens/Admin/AdminAllocatorQuestion/AdminAllocatorQuestion';
import AdminAllocatorValidatedProfile from 'screens/Admin/AdminAllocatorValidatedProfile/AdminAllocatorValidatedProfile';
import RequireAuth from '../helpers/RequireAuth';
import AdminUserManagement from 'screens/Admin/AdminUserManagement/AdminUserManagement';
import AdminUserManagementSingle from 'screens/Admin/AdminUserManagementSingle/AdminUserManagementSingle';
import Documents from 'screens/Documents/Documents';
import { OrganisationProvider } from 'providers/Organisation.provider';
import MainLayout from 'layouts/MainLayout/MainLayout';
import { RequireAdmin } from 'helpers/RequireAdmin';

const MainRouter = () => (
    <Router>
        <Switch>
            <RequireAuth>
                <OrganisationProvider>
                    <MainLayout>
                        <Switch>
                            <Route exact path="/">
                                <Dashboard />
                            </Route>
                            <Route path={Routes.DashboardRoute}>
                                <Dashboard />
                            </Route>
                            <Route path={Routes.DocumentsRoute}>
                                <Documents />
                            </Route>
                            {/* Admin Routes */}
                            <Route path={Routes.AdminAllocatorQuestionsRoute}>
                                <RequireAdmin>
                                    <AdminAllocatorQuestions />
                                </RequireAdmin>
                            </Route>
                            <Route
                                path={`${Routes.AdminAllocatorQuestionRoute}:id`}
                            >
                                <RequireAdmin>
                                    <AdminAllocatorQuestion />
                                </RequireAdmin>
                            </Route>
                            <Route
                                path={
                                    Routes.AdminAllocatorValidatedProfilesRoute
                                }
                            >
                                <RequireAdmin>
                                    <AdminAllocatorValidatedProfiles />
                                </RequireAdmin>
                            </Route>
                            <Route
                                path={`${Routes.AdminAllocatorValidatedProfileRoute}:id`}
                            >
                                <RequireAdmin>
                                    <AdminAllocatorValidatedProfile />
                                </RequireAdmin>
                            </Route>
                            <Route path={Routes.AdminUserManagementRoute}>
                                <RequireAdmin>
                                    <AdminUserManagement />
                                </RequireAdmin>
                            </Route>
                            <Route
                                path={`${Routes.AdminUserManagementDetailRoute}:id`}
                            >
                                <RequireAdmin>
                                    <AdminUserManagementSingle />
                                </RequireAdmin>
                            </Route>
                            <Route path={Routes.AdminRoute}>
                                <RequireAdmin>
                                    <Admin />
                                </RequireAdmin>
                            </Route>
                            {/* Allocator Routes */}
                            <Route path={Routes.AllocatorRoute}>
                                <Allocator />
                            </Route>
                            <Route path={`${Routes.ResultRoute}:id`}>
                                <Result />
                            </Route>
                            <Route path={Routes.ResultsRoute}>
                                <Results />
                            </Route>
                            <Route path={`${Routes.PdfRoute}:id/:language`}>
                                <Pdf />
                            </Route>
                            <Route path={`${Routes.PdfRoute}:id`}>
                                <Pdf />
                            </Route>
                            <Route path={`${Routes.JobMatrixPrint}:language`}>
                                <JobMatrixPrint />
                            </Route>
                            <Route
                                path={`${Routes.TalentMatrixPrint}:language`}
                            >
                                <TalentMatrixPrint />
                            </Route>
                            <Route path={Routes.ValuationRoute}>
                                <Valuation />
                            </Route>
                            <Route path="/update/:id">
                                <Update />
                            </Route>
                            <Route path={Routes.CreateUpdateRoute}>
                                <Update />
                            </Route>
                            <Route path={Routes.LatestUpdates}>
                                <LatestUpdates />
                            </Route>
                            <Route path={Routes.StyrManual}>
                                <StyrManual />
                            </Route>
                            {/* Manage organisation */}
                            <Route path={Routes.ManageOrganisationUsers}>
                                <ManageOrganisationUsers />
                            </Route>
                            <Route path={Routes.ManageOrganisationDetails}>
                                <ManageOrganisationDetails />
                            </Route>
                            <Route path={Routes.ManageShareLinks}>
                                <ManageShareLinks />
                            </Route>
                            <Route path={`${Routes.ShardRoute}:id`}>
                                <LoginSharedLink />
                            </Route>
                            {/* Matrix */}
                            <Route path={Routes.ManageJobMatrix}>
                                <ManageJobMatrix />
                            </Route>
                            <Route path={Routes.JobMatrixRoute}>
                                <JobMatrix />
                            </Route>
                            <Route path={Routes.TalentMatrix}>
                                <TalentMatrix />
                            </Route>
                            <Route path={Routes.ManageJobFamily}>
                                <ManageJobFamilyConnected />
                            </Route>
                            <Route path={`${Routes.JobFamilyEdit}:id`}>
                                <JobFamilyEditScreen />
                            </Route>
                            <Route path={`${Routes.JobFamilyView}:id`}>
                                <JobFamilyMatrixScreen />
                            </Route>
                            <Route path='*'>
                                <Dashboard />
                            </Route>
                        </Switch>
                    </MainLayout>
                </OrganisationProvider>
            </RequireAuth>
        </Switch>
    </Router>
);

export default MainRouter;
