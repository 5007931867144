import { connect } from 'react-redux';
import ReduxStore from 'store/store.type';
import AdminSidebar from './AdminSidebar';

const mapStateToProps = (state: ReduxStore) => ({
    sidebarOpen: state.navbar.sidebarOpen,
    currentLanguage: state.language.currentLanguage,
});

const mapDispatchToProps = { };

const ConnectedAdminSidebar = connect(mapStateToProps, mapDispatchToProps)(AdminSidebar);

export default ConnectedAdminSidebar;
