import { LangObject } from 'helpers/language';

export const de: LangObject = {
    id: '7cbacf26-2851-4e8b-815b-998a6f107ccd',
    styrAllocator: 'Styr Allocator',
    dashboard: 'Startseite',
    organisation: 'Organisation/Einheit',
    startAllocator: 'Allocator starten',
    searchJobsnRoles: 'Positionen/Rollen suchen',
    talentManagementTool: 'Talent-Management-System',
    elearningStyrModel: 'E-Learning-Modell Styr',
    styrUpdates: 'Release-informatie',
    forgotPassword: 'Passwort vergessen?',
    logout: 'Abmelden',
    edit: 'Edit', //TODO: Translate
    add: 'Hinzufügen',
    unitEmpty: 'Einheitsname darf nicht leer sein. Geben Sie einen Namen ein, um fortzufahren',
    unitAlreadyExists: 'Einheit besteht bereits. Wählen Sie einen anderen Namen, um fortzufahren',
    noUnits: 'Keine Einheiten verfügbar',
    editJob: 'Edit job', // TODO Translate
    editFilterOptions: 'Edit filter options', // TODO Translate
    styrProfile: 'Validiertes profile',
    viewValidatedProfile: 'Sicht Validiertes profile', // TODO Translate
    viewJobFamily: 'Sicht Job (Family)', // TODO Translate
    viewJobMatrix: 'Sicht Job Matrix', // TODO Translate
    talentMatrix: 'Talent-Matrix',
    theTalentMatrix: 'Die Talent-Matrix',
    chooseUserRole: 'Wählen Sie eine Benutzerrolle',
    userRole: 'Benutzer',
    jobMatrix: 'Job-Matrix',
    theJobMatrix: 'Die Job-Matrix',
    jobTalentMatrix: 'Job-Matrix / Talent-Matrix',
    jobMatrixArchitecture: 'Job-Matrix-Architektur',
    jobMatrixArchitectureName: 'Job-Matrix Architektur Namen',
    name: 'Name',
    firstName: 'Vorname',
    lastName: 'Nachname',
    pleaseEnterUnit: 'Geben Sie bitte eine Einheit ein',
    nameAlreadyExists: 'Name besteht bereits, geben Sie bitte einen anderen Namen ein, um fortzufahren',
    uploadImage: 'Abbildung hochladen',
    chooseImage: 'Abbildung auswählen',
    styrLevel: 'Styr level',
    filterTags: 'Tags',
    freeInputfieldColumn: 'Zusätzliche Job-Matrix-Spalte',
    freeInputfield: 'Zusätzliche Job-Matrix-Spalte',
    freeInputfield2: 'Zusätzliche Job-Matrix-Spalte 2',
    freeInputfield3: 'Zusätzliche Job-Matrix-Spalte 3',
    salaryScale: 'Gehaltsstufe',
    naturalCareerPath: 'Talentpfad',
    styrGroups: 'Styr group',
    styrGroup: 'Styr group',
    problemSolvingCapability: 'Problemlösungsfähigkeit',
    expertiseLevel: 'Expertiseniveau',
    planningHorizon: 'Planungshorizont',
    continuousImprovement: 'Kontinuierliche Verbesserung',
    autonomy: 'Autonomie',
    communicationSkills: 'Kommunikationskompetenzen',
    managementSkill: 'Koordinationskompetenzen (optionale)',
    management: 'Management',
    levelOfExpertise: 'Expertiseniveau',
    planning_horizon: 'Planungshorizont',
    continuous_improvement: 'Kontinuierliche Verbesserung',
    communication_skills: 'Kommunikationskompetenzen',
    coordinationSkills: 'Koordinationskompetenzen',
    projectManagementSkills: 'ProjekManagementkompetenzen',
    styr_competencies: 'STYR-TMA Kompetenzen',
    extra_competencies: 'Kompetenzen',
    educationLevelValidatedProfile: 'Arbeits- und Denkniveau/berufliche Erfahrung in Jahren (annähernd)',
    educationLevel: 'Expertiseniveau. Arbeits- und Denkniveau/berufliche Erfahrung in Jahren (annähernd)',
    education_level: 'Expertiseniveau. Arbeits- und Denkniveau/berufliche Erfahrung in Jahren (annähernd)',
    question: 'Wie lautet die Bezeichnung der Position oder Rolle?',
    unitShort: 'Architektur 2',
    organisationUnit: 'Organisation/Einheit',
    unit: 'Einheit',
    organisationUnitHasNoUnits: (organisationUnitName: string) => `Die Organisation/Einheit ${organisationUnitName} hat keine Einheiten`,
    jobAlreadyExist: 'Job besteht bereits',
    noNameEntered: 'Kein Name eingegeben',
    addNewOrganisationUnit: 'Zu Organisation/Einheit hinzufügen',
    addNewUnit: 'Einheit zufügen',
    unitMultiple: 'Einheiten',
    deleteWarningOrganisationUnit: 'Achtung! Wenn diese Organisation/Einheit entfernt wird, werden auch alle verknüpften Treffer in der Organisation/Einheit entfernt',
    deleteWarningUnit: 'Achtung! Wenn diese Einheit entfernt wird, werden auch alle verknüpften Treffer in der Einheit entfernt',
    deleteWarningUser: 'Möchten Sie diesen Benutzer wirklich löschen?',
    inviteNewUser: 'Neuen Benutzer einladen',
    inviteExistingUser: 'Bestehenden Benutzer einladen',
    inviteNewPartner: 'Neuen Berater einladen',
    userInvited: 'Neuer Benutzer eingeladen',
    partnerInvited: 'Neuer Berater eingeladen',
    next: 'Weiter',
    back: 'Zurück',
    close: 'Schließen',
    title: 'Titel',
    description: 'Beschreibung',
    comment: 'Nachricht',
    noResults: 'Keine Treffer',
    placeholder: (item: string) => `Geben Sie bitte Ihr ${item} ein...`,
    placeholderError: (item: string) => `Geben Sie hier Ihr ${item} ein!`,
    post: 'Senden',
    attachment: 'Anlage',
    user: 'Benutzer',
    users: 'Benutzer',
    manageUsers: 'Benutzer verwalten',
    submit: 'Versenden',
    updates: 'Letzte Updates',
    recentAllocated: 'Zuletzt zugewiesen',
    questionsServiceDeskHome: 'Wenden Sie sich bei Fragen zuerst an den Styr-Helpdesk.',
    date: 'Datum',
    retry: 'Wiederholen',
    role: 'Profil',
    functionOrRole: 'Position/Rolle',
    jobRole: 'Profiltitel',
    jobNRole: 'Profile',
    level: 'Niveau',
    organisationName: 'Name der Organisation',
    createDetails: 'Details anlegen',
    createdOn: 'Erstellt am',
    createOrganisation: 'Organisation anlegen',
    createUser: 'Neuen Benutzer anlegen',
    createNewOrganisation: 'Organisation hinzufügen',
    note: 'Zusätzliche Information',
    save: 'Speichern',
    saving: 'Wird gespeichert...',
    saveResults: 'Wird gespeichert...',
    delete: 'Löschen',
    deletePlural: 'Löschen',
    newName: 'Neuer Name',
    editPlural: 'Bearbeiten',
    confirmation: (action: string, item: string) => `"Sind Sie sicher, dass Sie ${item} ${action} wollen?`,
    result: 'Ergebnis',
    cancel: 'Rückgängig machen',
    freeInputLevel: 'Zusätzliche Job-Matrix-Spalte',
    columns: 'Spalten',
    compare: 'Vergleichen',
    choose: 'Auswählen',
    change: 'Ändern',
    manageOrganisation: 'Organisationsverwaltung',
    manageValuation: 'Bewertung verwalten',
    manageValuationDescription: 'Verknüpfen Sie die klassischen job grading levels mit den Styr levels. \n\nHinweis! Dadurch bleiben nur die klassischen job grading levels im Job Profiler-Modul sichtbar.',
    pleaseSelectAtleastTwo: 'Wählen Sie zum Vergleichen mindestens 2 Ebenen',
    styrDifferencingFactors: 'Styr Unterscheidende Faktoren',
    showTalentpath: 'Talentpfad ansehen',
    changeStyrLevel: 'Styr-Ebenen vergleichen',
    selectLevelAfterTalentpathChange: 'Bitte wählen Sie eine Ebene aus, nachdem Sie den Talentpfad geändert haben',
    backToValidatedProfile: 'Zurück zum Profil',
    backToPreview: 'Zurück zur Vorabansicht',
    changeLevel: 'Styr-Ebene ändern',
    chooseStyrLevels: (current: number) => `Styr-Ebene wählen (max ${current}/3)`,
    selectStyrLevelsToCompare: (current: number) => `Wählen Sie die Ebenen aus, die Sie vergleichen wollen (max. ${current}/3)`,
    currentValidatedProfileLevelIs: 'Die aktuelle Styr-Ebene ist ',
    youreAboutToChange: 'Sie wollen diese Ebene ändern zu Ebene ',
    changing: 'Anpassen',
    theStyrLevel: 'Die Styr-Ebene',
    talentpath: 'Talentpfad',
    view: 'Ansehen',
    viewInformation: 'Zusätzliche Information',
    allocatorWarningOrganisation: 'Der Styr Allocator kann erst genutzt werden, wenn zur Job-Matrix-Architektur eine Organisation hinzugefügt wurde.',
    maxLogoWidthDescription: 'Breite Logo max. 1000 px',
    maxLogoWidthWarning: 'Abbildung ist zu breit',
    supportedFileTypes: 'Unterstützte Dateitypen',
    goToManagement: 'Weiter zu Verwaltung',
    talentpathAdministrative: 'Administration',
    talentpathAdvisory: 'Beratung',
    talentpathCommercial: 'Vertrieb',
    talentpathExpert: 'Experte',
    talentpathManagement: 'Management',
    talentpathProjectManagement: 'Projektmanagement',
    talentpathSupportCoordination: 'Support & Koordination',
    talentpathProcessManagement: 'Prozessmanagement',
    talentpathOperationalTechnical: 'Operativ/Technisch',
    manageJobFamily: 'Job Profiler',
    manageJobFamilyNotEnabled: 'Job(-Familien) sind für die aktuelle Organisation nicht verfügbar',
    search: 'Suchen',
    jobFamilyAlreadyExist: 'Job(-Familie) besteht bereits',
    pleaseEnterFamilyName: 'Name Job(-Familie) vergessen',
    addJobFamily: 'Job(-Familie) hinzufügen',
    goToSettings: 'Einstellungen',
    addNewJob: 'Neuen Job anlegen',
    newJobFamily: 'Neue Jobfamilie',
    nameJobFamily: "Name Jobfamilie'",
    status: 'Status',
    changeStatusBulk: 'Change status:', //TODO: Translation Change status
    jobFamily: 'Jobfamilie',
    lastModified: 'Zuletzt geändert',
    differentiatingFactorsTitle: 'Unterscheidende Faktoren',
    changeDifferentiatingFactorsTitle: 'Unterscheidenden Faktoren Verwalten',
    differentiatingFactorsDescription: 'Sichtbarkeit der unterscheidenden Faktoren verwalten; öffnen Sie die Einstellungen, um sie zu aktivieren oder zu deaktivieren',
    competencies: 'Kompetenzen',
    defaultStyrCompetencies: 'STYR-TMA Kompetenzen',
    extraCompetencies: 'Zusätzliche Kompetenzen',
    mainResponsibilities: 'Hauptverantwortlichkeiten',
    manage: 'Verwalten',
    row: 'Reihe',
    enterTitle: 'Titel hinzufügen',
    enterDescription: 'Beschreibung hinzufügen',
    addExtraCompetence: 'Kompetenz hinzufügen',
    manageRows: 'Reihen verwalten',
    createNewJob: 'Neuen Job anlegen',
    selectOrganisationUnit: 'Organisation/Einheit auswählen',
    selectUnit: 'Einheit auswählen',
    selectTalentPath: 'Talentpfad auswählen',
    selectLevel: 'Styr Ebene auswählen',
    pleaseFillInAllField: 'Offenbar haben Sie etwas vergessen.',
    accountability: 'Verantwortungsbewusstsein',
    attention_to_detail: 'Detailgenauigkeit',
    business_orientation: 'Unternehmergeist',
    commercial_power: 'Verkäuferisches Geschick',
    conduct: 'Auftreten',
    controlling_progress: 'Verlaufskontrolle',
    cooperation: 'Teamfähigkeit',
    creativity: 'Kreativität',
    customer_orientation: 'Kundenorientierung',
    decisiveness: 'Entschlusskraft',
    delegating: 'Delegieren',
    developing_employees: 'Mitarbeiterentwicklung',
    discipline_competence: 'Disziplin',
    flexible_behavior: 'Flexibilität',
    focus_on_quality: 'Qualitätsbewusstsein',
    forming_judgment: 'Urteilsbildung',
    identification_with_management: 'Identifikation mit dem Management',
    independence: 'Unabhängigkeit',
    innovative_power: 'Innovative Kraft',
    insight: 'Auffassungsvermögen',
    leadership_of_groups: 'Teamführung',
    managing: 'Führungsstärke',
    need_to_achieve: 'Leistungsorientiertheit',
    networking: 'Netzwerken',
    perseverance: 'Beharrlichkeit',
    persuasiveness: 'Überzeugungskraft',
    planning_and_organizing: 'Planen und Organisieren',
    result_orientedness: 'Ergebnisorientiertheit',
    sociability: 'Kontaktfreudigkeit',
    social_awareness: 'Soziales Bewusstsein',
    verbal_expression: 'Rhetorisches Geschick',
    vision: 'Visionäre Kraft',
    workmanship: 'Fachliche Professionalität',
    courage: 'Audace',
    integrity: 'Intégrité',
    written_expression: 'Schriftlicher Ausdruck',
    backToJobFamilies: 'Zurück zu Jobfamilien',
    showAll: 'Alles anzeigen',
    jobfamilySearchPlaceholder: 'Suche nach: Titel, Organisation, Einheit, Ebene usw...',
    filterResults: 'Profile filtern',
    password: 'Passwort',
    accessCode: 'Access code',
    accessCodeDescription: 'The access code is not a password. The share link in combination with the access code can be shared through an intranet or similar secure channel. Can not contain special characters.',
    accessCodeError: 'Can not contain special characters.',
    usernameOrEmail: 'Username oder E-Mail Adresse',
    usernameEmpty: 'Bitte geben Sie Ihren Usernamen ein, um fortzufahren',
    passwordEmpty: 'Bitte geben Sie Ihr Passwort ein, um fortzufahren',
    microsoftNotWorking: 'Ihr Account konnte nicht verifiziert werden.',
    switchOrganisation: 'Organisation wechseln',
    viewOtherOrganisations: 'Andere Organisationen anzeigen',

    login: 'Einloggen',
    forgotPasswordTitle: 'Haben Sie Ihr Passwort vergessen?',
    backToLogin: 'Zurück zur Anmeldung',
    requestNewPassword: 'Passwort anfragen',
    tryAgain: 'Erneut versuchen',
    newPasswordSend: 'Die E-Mail mit dem Link zum Passwort-Reset wurde versandt. Haben Sie die E-Mail nicht erhalten?',
    emailFieldEmpty: 'Das E-Mail-Feld ist leer',
    resetPasswordTitle: 'Setzen Sie Ihr Passwort neu ein',
    resetPasswordSuccess: 'Ihr Passwort wurde erfolgreich wiederhergestellt.',
    newPassword: 'Neues Passwort',
    duplicateOrganisation: 'Aktuelle Organisation duplizieren',
    deleteOrganisation: 'Delete Organisation',

    // SHARELINKS
    manageShareLinkTitle: 'Teilen-Link',
    manageShareLinkDescription: `
    Führen Sie die folgenden 3 Schritte aus, um einen gemeinsamen Link zu erstellen, über den Mitarbeitern auf die Job-/Talentmatrix und ihre Profile zugreifen können. Nur Profile, die auf aktiv gesetzt sind, werden in der Job-/Talentmatrix angezeigt.

    1) Klicken Sie auf "Neuen Link erstellen" und wählen Sie die Ansicht aus, die Sie anzeigen möchten.
    2) Geben Sie ein Access code ein, um den freigegebenen Link zu sichern.
    3) Geben Sie schließlich den Link und das Access code an den vorgesehenen Empfänger weiter und stellen Sie sicher, dass dies vertraulich behandelt wird, um den vorgesehenen Zugang/Sicherheit zu gewährleisten.
    `,

    createNewLink: 'Neuen Link erstellen',
    link: 'Link',
    dateAdded: 'Datum hinzugefügt',

    questionDeleteShareLink: 'Sind Sie sicher, dass Sie löschen wollen?',

    createNewShareLinkTitle: 'Erstellen Sie einen Teilen-Link',
    createNewShareLinkDescription: 'Stellen Sie die Anzeigerechte so ein, dass die Mitarbeiter Zugriff auf die relevanten Organisationen/Einheiten und Styr-Ebenen haben. Sie müssen Ihren Link mit einem Passwort schützen.',

    allOrganisationUnitsTitle: 'Alle',
    createdBy: 'Klassifiziert durch',
    updatedBy: 'Zuletzt bearbeitet von',

    headerSearchbarNoResults: 'Keine Ergebnisse gefunden',
    headerSearchbarPlaceholder: '',

    createNewProfile: 'Neues Profil erstellen',
    pleaseSelectAnAnswer: 'Bitte wählen Sie eine Antwort aus, um fortzufahren',

    pleaseSelectALevel: 'Bitte wählen Sie eine Stufe für den neuen Talentpfad.',
    showStyrProfile: 'Validiertes Profil anzeigen',

    createUpdate: 'Create update',
    allocatorQuestions: 'Allocator Questions',
    allocatorQuestion: 'Allocator Question',

    errorTitle: 'Etwas ist schief gelaufen',
    errorFailedFetchText: "Wir können keine Verbindung zum Server herstellen. Bitte wenden Sie sich an den <a href='mailto:helpdesk@styr.nl'>Helpdesk</a>, wenn dieses Problem weiterhin besteht. ",
    errorEndText: 'Versuchen Sie es später noch einmal',

    tagsHeader: 'Tags auswählen',
    tagsUntagged: 'Nicht markiert',
    tagsFutureState: 'Zukünftiger Zustand',
    tagsCurrentState: 'Aktueller Zustand',

    multiselectAllItemsAreSelected: 'Alle Elemente sind ausgewählt',
    multiselectClearSearch: 'Saubere Suche',
    multiselectClearSelected: 'Auswahl löschen',
    multiselectNoOptions: 'Keine Optionen',
    multiselectSearch: 'Suchen',
    multiselectSelectAll: 'Wählen Sie Alle',
    multiselectSelectAllFiltered: 'Alles auswählen (Gefiltert)',
    multiselectSelectSomeItems: 'Wählen...',
    multiselectCreate: 'Erstellen',
    mulitselectSelect: 'Wählen',

    //Allocator Error Messages
    loadQuestions: 'Die angeforderten Fragen können nicht geladen werden, bitte versuchen Sie es später erneut',
    loadValidatedProfiles: 'Die validierten Profile können nicht geladen werden, bitte versuchen Sie es später erneut',
    loadLevels: 'Die Ebenen können nicht geladen werden, bitte versuchen Sie es später erneut',
    loadTalentpaths: 'Die Talentpfade können nicht geladen werden, bitte versuchen Sie es später erneut',
    createQuestion: 'Die Frage kann nicht erstellt werden, bitte versuchen Sie es später erneut',
    updateQuestion: 'Die Frage kann nicht aktualisiert werden, bitte versuchen Sie es später erneut',
    deleteQuestion: 'Die Frage kann nicht gelöscht werden, bitte versuchen Sie es später erneut',
    createAnswer: 'Die Antwort kann nicht erstellt werden, bitte versuchen Sie es später erneut',
    updateAnswer: 'Die Antwort kann nicht aktualisiert werden, bitte versuchen Sie es später erneut',
    deleteAnswer: 'Die Antwort kann nicht gelöscht werden, bitte versuchen Sie es später erneut',
    createValidatedProfile: 'Das validierte Profil kann nicht erstellt werden, bitte versuchen Sie es später erneut',
    updateValidatedProfile: 'Das validierte Profil kann nicht aktualisiert werden, bitte versuchen Sie es später erneut',
    deleteValidatedProfile: 'Das validierte Profil kann nicht gelöscht werden, bitte versuchen Sie es später erneut',

    //User Error Messages
    updateOrganisationRequest: 'Konnte die Organisation nicht abrufen, bitte versuchen Sie es später erneut',
    userSharedLinkLogin: 'Kann nicht mit dem freigegebenen Link anmelden, bitte versuchen Sie es später erneut',
    updateOrganisationUsers: 'Kann die Benutzer der Organisation nicht abrufen, bitte versuchen Sie es später erneut',
    updateOrganisationsRequest: 'Kann die Organisationen nicht abrufen, bitte versuchen Sie es später erneut',
    changeRole: 'Kann die Rolle des Benutzers nicht ändern, bitte versuchen Sie es später erneut',
    inviteUser: 'Kann den Benutzer nicht einladen, bitte versuchen Sie es später erneut',
    invitePartner: 'Kann den Partner nicht einladen, bitte versuchen Sie es später erneut',
    deleteUser: 'Kann den Benutzer nicht löschen, bitte versuchen Sie es später erneut',
    deleteOrganisationError: 'Kann die Organisation nicht löschen, bitte versuchen Sie es später erneut',
    duplicateOrganisationError: 'Kann die Organisation nicht duplizieren, bitte versuchen Sie es später erneut',
    createNewOrganisationError: 'Kann keine neue Organisation erstellen, bitte versuchen Sie es später erneut',
    updateOrganisationValuation: 'Kann die Bewertung der Organisation nicht aktualisieren, bitte versuchen Sie es später erneut',
    updateOrganisationTMALink: 'Kann den TMA-Link der Organisation nicht aktualisieren, bitte versuchen Sie es später erneut',
    updateOrganisationJobFamilies: 'Kann die Jobfamilien der Organisation nicht aktualisieren, bitte versuchen Sie es später erneut',
    updateOrganisationName: 'Kann den Namen der Organisation nicht aktualisieren, bitte versuchen Sie es später erneut',
    updateResultInFamily: 'Kann das job in der Familie nicht aktualisieren, bitte versuchen Sie es später erneut',
    addOrganisationUnit: 'Kann die Organisationseinheit nicht hinzufügen, bitte versuchen Sie es später erneut',
    updateOrganisationUnit: 'Kann die Organisationseinheit nicht aktualisieren, bitte versuchen Sie es später erneut',
    updateOrganisationMatrixes: 'Organisationsmatrizen konnten nicht aktiviert oder deaktiviert werden. Bitte versuchen Sie es später noch einmal',
    deleteOrganisationUnit: 'Kann die Organisationseinheit nicht löschen, bitte versuchen Sie es später erneut',
    addUnit: 'Kann die Einheit nicht hinzufügen, bitte versuchen Sie es später erneut',
    deleteUnit: 'Kann die Einheit nicht löschen, bitte versuchen Sie es später erneut',
    updateUnit: 'Kann die Einheit nicht aktualisieren, bitte versuchen Sie es später erneut',
    updateUnitsAndOrganisationUnitsOrder: 'Kann die Reihenfolge der Einheiten und Organisationseinheiten nicht aktualisieren, bitte versuchen Sie es später erneut',
    updateLevelNotes: 'Kann die Level-Notizen nicht aktualisieren, bitte versuchen Sie es später erneut',
    uploadOrganisationImage: 'Kann das Organisationsbild nicht hochladen, bitte versuchen Sie es später erneut',
    createShareLink: 'Kann den Freigabelink nicht erstellen, bitte versuchen Sie es später erneut',
    updateShareLink: 'Kann den Freigabelink nicht aktualisieren, bitte versuchen Sie es später erneut',
    deleteShareLink: 'Kann den Freigabelink nicht löschen, bitte versuchen Sie es später erneut',
    updatePermissions: 'Kann die Berechtigungen nicht aktualisieren, bitte versuchen Sie es später erneut',
    getRole: 'Kann die Rolle nicht abrufen, bitte versuchen Sie es später erneut',
    loadAllOrganisationsRequest: 'Kann die Organisationen nicht abrufen, bitte versuchen Sie es später erneut',
    loadSelectedUserRequest: 'Kann den ausgewählten Benutzer nicht abrufen, bitte versuchen Sie es später erneut',
    updateUserRequest: 'Kann den Benutzer nicht aktualisieren, bitte versuchen Sie es später erneut',
    loadPagedUserRequest: 'Kann die Benutzer nicht abrufen, bitte versuchen Sie es später erneut',

    //Update Error Messages
    updatesCreateUpdate: 'Kann im Moment kein Update erstellen, bitte versuchen Sie es später erneut',
    updatesGetUpdates: 'Kann im Moment keine Updates abrufen, bitte versuchen Sie es später erneut',
    updatesChangeUpdate: 'Kann im Moment kein Update ändern, bitte versuchen Sie es später erneut',
    updatesDeleteUpdate: 'Kann im Moment das Update nicht löschen, bitte versuchen Sie es später erneut',

    //Results Error Messages
    getResult: 'Konnte die angeforderten job nicht abrufen, bitte versuchen Sie es später noch einmal.',
    getResults: 'Konnte die angeforderten jobs nicht abrufen, bitte versuchen Sie es später noch einmal.',
    postResult: 'Konnte den job nicht erstellen, bitte versuchen Sie es später noch einmal.',
    postResultManual: 'Konnte den job nicht erstellen, bitte versuchen Sie es später noch einmal.',
    changeStatusBulkError: 'Konnte den Status der jobs nicht in Bulk ändern, bitte versuchen Sie es später noch einmal.',
    changeResult: 'Konnte den job nicht bearbeiten, bitte versuchen Sie es später noch einmal.',
    changeResultDetails: 'Konnte die jobdetails nicht ändern, bitte versuchen Sie es später noch einmal.',
    deleteResult: 'Konnte den job nicht löschen, bitte versuchen Sie es später noch einmal.',
    changeResultValidatedProfile: 'Konnte das Styr-Level des validierten Profils nicht ändern, bitte versuchen Sie es später noch einmal.',
    updateResultByField: 'Konnte die jobfamily nicht aktualisieren, bitte versuchen Sie es später noch einmal.',
    updateResultExtraCompetence: 'Konnte die zusätzliche Kompetenz des jobs nicht aktualisieren, bitte versuchen Sie es später noch einmal.',
    changeResultExtraCompetenceOrder: 'Konnte die Reihenfolge der zusätzlichen Kompetenzen des jobs nicht ändern, bitte versuchen Sie es später noch einmal.',
    saveExtraCompetence: 'Konnte die zusätzliche Kompetenz des jobs nicht speichern, bitte versuchen Sie es später noch einmal.',

    //Language Error Messages
    getRemoteLanguages: 'Die Remote-Sprachen können derzeit nicht abgerufen werden, bitte versuchen Sie es später erneut.',

    //JobFamily Error Messages
    createJobfamily: 'Kann im Moment keine Jobfamilie erstellen, bitte versuchen Sie es später erneut',
    updateJobfamily: 'Kann im Moment die Jobfamilie nicht aktualisieren, bitte versuchen Sie es später erneut',
    deleteJobfamily: 'Kann im Moment die Jobfamilie nicht löschen, bitte versuchen Sie es später erneut',
    updateDifferentiatingFactor: 'Kann im Moment das differenzierende Merkmal nicht aktualisieren, bitte versuchen Sie es später erneut',
    updateJobFamilyByField: 'Kann im Moment die Jobfamilie nicht aktualisieren, bitte versuchen Sie es später erneut',

    paginationTake: 'Anzahl',
    filterOrganisation: 'Organisation filtern',
    organisations: 'Organisationen',
    allOrganisations: 'Alle Organisationen',
    userExportFileName: 'Benutzerexport',

    dockInformation: 'Dock-Information',
    manual: 'Handbuch Styr Dock (EN)',
    ictIPS: 'ICT IPS',
    faq: 'FAQ Styr model (EN)',
    flyerCompensation: 'Flyer Compensation (EN)',
    flyerJobMatrix: 'Flyer Job Matrix (EN)',
    implementation: 'Implementation (EN)',
    benchmark: 'Reward Benchmark (EN)',
    compensationPolicy: 'Reward Policy (EN)',
    faqJobMatrixImplementation: 'FAQ Job Matrix Implementierung (EN)',
    faqNewRewardsPolicy: 'FAQ New Rewards Policy (EN)',
    confirmationLetter: 'Format des Bestätigungsschreibens (EN)',
    objectionProcedure: 'Objection Procedure (EN)',
    templateAgreementCompensation: 'Template Agreement Compensation (EN)',
    templateAgreementJobGrading: 'Template Agreement Job Grading (EN)',
    templateMaintenanceMatrix: 'Template Maintenance Matrix (EN)',
    templateMaintenanceGeneral: 'Template Maintenance General (EN)',
    templatePlanningImplementation: 'Template Planning Implementation (EN)',
    templateFunctionAprisal: 'Request for approval of job evaluation (EN)',

    manageMatrixes: "Matrizen verwalten",
    manageMatrixesDescription: "1 Matrix muss immer aktiv sein. Wenn beide Matrizen ausgeschaltet sind, wird 1 Matrix automatisch aktiviert.",

    noPermissionHeader: 'Keine Berechtigung',
    noPermissionText: 'Sie haben keine Berechtigung, diese Seite zu sehen. Bitte kontaktieren Sie den Administrator, wenn Sie denken, dass dies ein Fehler ist.',

    elementDisabledHeader: (element: string) => `${element} deaktiviert`,
    elementDisabledText: (element: string) => `Das ${element} ist deaktiviert. Bitte kontaktieren Sie den Administrator, wenn Sie denken, dass dies ein Fehler ist.`,

    //JobFamily Images
    writeDescription: 'Beschreibung schreiben',
    createNewVersion: 'Neue Version erstellen',
    noExistingFiles: 'Keine vorhandenen Dateien',
    versionManagement: 'Versionen verwalten',

    ssoEditHeader: 'SSO-Konfiguration',
    ssoDomain: 'SSO-Domäne (alles nach dem @)',
    ssoTenantId: 'SSO-Mandanten-ID (Microsoft Entra-Mandanten-ID)',
    saveSSOConfiguration: 'SSO-Konfiguration speichern',
    updateCurrentSsoConfiguration: 'Die SSO-Konfiguration konnte nicht aktualisiert werden. Bitte versuchen Sie es später erneut.',
    getCurrentSsoConfigurations: 'Die SSO-Konfigurationen konnten nicht abgerufen werden. Bitte versuchen Sie es später erneut.',

    documentOverview: 'Wissensbasis',
    jobFamilyVersion: 'Jobfamilie Version',

    updateOrganisationContractContactPerson: 'Der Vertragskontakt der Organisation konnte nicht aktualisiert werden. Bitte versuchen Sie es später erneut.',
    manageContractContactPersons: 'Vertragskontakte verwalten',
    contractContactPerson: 'Vertragskontakt',

    organisationNameDescription: 'Der Name der Organisation',
    tmaLinkDescription: 'Die Verbindung zu TMA',
    manageContractContactPersonsDescription: 'Die E-Mail-Adresse des Vertragsansprechpartners',
    ssoEditDescription: 'Konfigurieren Sie die (Microsoft) SSO-Einstellungen für die Organisation',
    createOrganisationDescription: 'Erstellen einer neuen Organisation',
    deleteOrganisationDescription: 'Die aktuelle Organisation löschen',
    duplicateOrganisationDescription: 'Duplizieren der aktuellen Organisation',
    manageJobFamilyDescription: 'Jobprofiler (de)aktivieren',
    manageDifferentiatingFactorsDescription: 'Verwalten Sie die Sichtbarkeitsdifferenzierungsfaktoren',

    showTags: 'Tags anzeigen',

    noDocumentAvailable: 'Kein Dokument verfügbar',
    documentTypeImplementation: 'Durchführung Job Matrix',
    documentTypeRewardsdata: 'Belohnungen',
    documentTypeMaintenance: 'Wartung',
    documentTypeStyrModel: 'Styr Model',
    implementationRewardsPolicy: 'Belohnungspolitik',

    updateUserName: 'Der Benutzername konnte nicht aktualisiert werden. Bitte versuchen Sie es später erneut.',
    updateExistingUser: 'Vorhandenen Benutzer aktualisieren',

    validationValidValue: 'Vul een geldige waarde in',
    validationToLong: 'Waarde is te lang',
};
