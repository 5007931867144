import SidebarItem from 'components/SidebarItem/SidebarItem';
import { SidebarDivider } from 'components/SidebarItem/SidebarItem.components';
import getLanguageObject from 'helpers/language';
import React from 'react';
import Routes from 'routes/Routes.types';
import ConnectedCurrentUserSidebarItem, {
    SidebarContainer,
    SidebarNav
} from './Sidebar.components';
import { AdminSidebarProps } from './Sidebar.types';
import { useMsal } from '@azure/msal-react';
import LogoutIcon from 'components/Icons/logout';
import AllocatorIcon from 'components/Icons/allocator';
import JobProfilerIcon from 'components/Icons/jobProfiler';
import UsersIcon from 'components/Icons/users';
import UserActions from 'store/user/user.constants';
import { useDispatch } from 'react-redux';

const AdminSidebar: React.FC<AdminSidebarProps> = ({
    sidebarOpen,
    currentLanguage,
}) => {
    const dispatch = useDispatch();
    const { instance } = useMsal();
    const lang = getLanguageObject(currentLanguage);

    const logout = async () => {
        dispatch({
            type: UserActions.Logout,
        })
        instance.logout();
    }

    return (
        <SidebarContainer>
            <SidebarNav full={sidebarOpen}>
                <div>
                    <SidebarItem
                        sidebarOpen={sidebarOpen}
                        IconSvg={AllocatorIcon}
                        text={lang.allocatorQuestions}
                        navigateToPage={Routes.AdminAllocatorQuestionsRoute}
                    />
                    <SidebarItem
                        sidebarOpen={sidebarOpen}
                        IconSvg={JobProfilerIcon}
                        text={lang.styrProfile}
                        navigateToPage={Routes.AdminAllocatorValidatedProfilesRoute}
                    />
                    <SidebarItem
                        sidebarOpen={sidebarOpen}
                        IconSvg={UsersIcon}
                        text={lang.manageUsers}
                        navigateToPage={Routes.AdminUserManagementRoute}
                    />
                </div>
                <div>
                    <SidebarDivider />
                    <ConnectedCurrentUserSidebarItem />
                    <SidebarDivider />
                    <SidebarItem
                        sidebarOpen={sidebarOpen}
                        IconSvg={LogoutIcon}
                        text={lang.logout}
                        onClick={logout}
                    />
                </div>
            </SidebarNav>
        </SidebarContainer>
    );
};

export default AdminSidebar;
