import apiRequest from 'helpers/api';
import { History } from 'history';
import { AnyAction, Dispatch } from 'redux';
import Routes from 'routes/Routes.types';
import { allocatorRetake, loadValidatedProfiles } from 'store/allocator/allocator.actions';
import store from 'store/store';
import { ApiError } from 'types/Api';
import { Result, ResultPost, Status } from 'types/Result';
import ResultsActions, { TagTypes } from './results.constants';
import { convertMultilingualStringToLanguageObject, getCurrentLanguageId } from 'store/language/language.actions';
import { Language, MultiLingualString } from 'types/MultiLingualString';
import * as Sentry from "@sentry/react";
import { getToken } from 'store/tokenHelper';
import getLanguageObject from 'helpers/language';
import { handleApiError } from "../../helpers/errorHandler";
import { ResultErrorTypes } from "./results.errors";
import { Domain } from "../../types/Domain";

export const resultsAddError = (error: string) => (dispatch: Dispatch) => {
    return dispatch({
        type: ResultsActions.AddError,
        payload: {
            error,
        },
    });
};

export const resultsGetAllTags = (currentLanguage: Language) => {
    const lang = getLanguageObject(currentLanguage)
    return [{
        name: lang.tagsCurrentState,
        id: TagTypes.tagsCurrentState
    }, {
        name: lang.tagsFutureState,
        id: TagTypes.tagsFutureState
    }];
}

export type ResultsGetResult = (id: string) => void;
export const resultsGetResult: ResultsGetResult = id => (
    dispatch: Dispatch<AnyAction>
) => {
    const endpoint = `jobs/${id}`;

    loadValidatedProfiles()(dispatch).then((validatedProfiles) => {
        const state = store.getState();

        apiRequest(endpoint, 'GET', state.user.jwt, false, {
            isShareLink: state.user.isShareLink,
            currentOrganisation: state.user.currentOrganisation?.id
        })
            .then((data: Result) => {
                const validatedProfile = validatedProfiles.find(o => o.id === data.validatedProfileId);
                return dispatch({
                    type: ResultsActions.UpdateResult,
                    payload: {
                        result: {
                            ...data,
                            validatedProfile: validatedProfile,
                            level: validatedProfile?.styrLevel.level,
                            status: data.status.toLowerCase() as Status
                        },
                    },
                });
            })
            .catch((error: ApiError) => {
                handleApiError(Domain.Results, ResultErrorTypes.getResult, state.language.currentLanguage)
                return dispatch({
                    type: ResultsActions.AddError,
                    payload: {
                        error: error.message,
                    },
                });
            });
    })
};

export type ResultsGetResults = () => void;
export const resultsGetResults = () => (dispatch: Dispatch) => {
    const state = store.getState();
    const endpoint = `jobs/list/${state.user.currentOrganisation?.id}`;

    Promise.all([loadValidatedProfiles()(dispatch), apiRequest(endpoint, 'GET', state.user.jwt, false, {
        isShareLink: state.user.isShareLink,
        currentOrganisation: state.user.currentOrganisation?.id
    })])
        .then(([validatedProfiles, data]) => {
            const results: Result[] = data || [];

            let newResults = results.map(r => {
                return {
                    ...r,
                    validatedProfile: validatedProfiles.find(o => o.id === r.validatedProfileId),
                    status: r.status.toLowerCase() as Status,
                    jobCompetences: r.jobCompetences?.sort((a, b) => {
                        if (!a.sortOrder || !b.sortOrder) {
                            return 0
                        }
                        return a.sortOrder - b.sortOrder
                    })
                }
            });

            if (state.user.isShareLink) {
                newResults = newResults.filter(r => r.status?.toLowerCase() === "active")
            }

            return dispatch({
                type: ResultsActions.UpdateResults,
                payload: {
                    results: newResults,
                },
            });
        })
        .catch((error: ApiError) => {
            Sentry.captureException(error)
            handleApiError(Domain.Results, ResultErrorTypes.getResults, state.language.currentLanguage)
            return dispatch({
                type: ResultsActions.AddError,
                payload: {
                    error: error.message,
                },
            });
        });
};

export type ResultsPostResult = (validatedProfile: string, history: History) => void;
export const resultsPostResult = (validatedProfile: string, history: History) => (
    dispatch: Dispatch
) => {
    const state = store.getState();
    const endpoint = 'jobs';

    if (!state.allocator.profile) {
        return dispatch({
            type: ResultsActions.AddError,
            payload: {
                error: 'Posting result but profile was not entered',
            },
        });
    }

    const questionHistory = state.allocator.questions.history[0];
    const type =
        questionHistory && questionHistory.selectedAnswers.length > 0
            ? questionHistory.selectedAnswers[0].text["en-EN"]
            : '';

    const result = {
        organisationId: state.user.currentOrganisation?.id,
        name: state.allocator.profile.name,
        unitId: state.allocator.profile.unit,
        type,
        validatedProfileId: validatedProfile,
        status: state.user.permissions.canUpdateStatusResult ? "Active" : "Concept",
        tags: state.allocator.profile.tags,
    };
    apiRequest(endpoint, 'POST', state.user.jwt, result, {
        currentOrganisation: state.user.currentOrganisation?.id
    })
        .then((data: Result) => {
            history.push(Routes.ResultRoute + data.id);
            dispatch({
                type: ResultsActions.UpdateResult,
                payload: {
                    result: data,
                },
            });
            allocatorRetake()(dispatch);
        })
        .catch((error: ApiError) => {
            Sentry.captureException(error)
            handleApiError(Domain.Results, ResultErrorTypes.postResult, state.language.currentLanguage)
            return dispatch({
                type: ResultsActions.AddError,
                payload: {
                    error: error.message,
                },
            });
        });
};

export type ResultsPostResultManual = (newResult: ResultPost) => void;
export const resultsPostResultManual = (newResult: ResultPost) => (
    dispatch: Dispatch
) => {
    const state = store.getState();
    const endpoint = 'jobs';

    const result = {
        organisationId: state.user.currentOrganisation?.id,
        name: newResult.name,
        unitId: newResult.unit,
        validatedProfileId: newResult.validatedProfileId,
        status: state.user.permissions.canUpdateStatusResult ? "Active" : "Concept",
    };

    apiRequest(endpoint, 'POST', state.user.jwt, result, {
        currentOrganisation: state.user.currentOrganisation?.id
    })
        .then((data: Result) => {
            dispatch({
                type: ResultsActions.UpdateResult,
                payload: {
                    result: data,
                },
            });
            resultsGetResults()(dispatch);
        })
        .catch((error: ApiError) => {
            Sentry.captureException(error)
            handleApiError(Domain.Results, ResultErrorTypes.postResultManual, state.language.currentLanguage)
            return dispatch({
                type: ResultsActions.AddError,
                payload: {
                    error: error.message,
                },
            });
        });
};

export type ResultChangeStatusBulk = (
    results: string[],
    newStatus: Status,
) => void;

export const resultChangeStatusBulk = (
    results: string[],
    newStatus: Status,
) => (dispatch: Dispatch) => {
    const state = store.getState();
    const endpoint = 'jobs/bulk';

    const message = {
        jobIds: results,
        status: newStatus,
    };

    apiRequest(endpoint, 'PUT', state.user.jwt, message, {
        currentOrganisation: state.user.currentOrganisation?.id
    })
        .then(() => {
            resultsGetResults()(dispatch);
        })
        .catch((error: ApiError) => {
            handleApiError(Domain.Results, ResultErrorTypes.changeStatusBulk, state.language.currentLanguage)
            console.error(error)
        });
};


export type ResultsChangeResult = (
    status: string,
    note: string,
    id: string,
    history: History
) => void;

export const resultsChangeResult = (
    status: string,
    note: string,
    id: string,
    history: History
) => (dispatch: Dispatch) => {
    const state = store.getState();
    const endpoint = 'jobs';
    const currentLanguageCode = getCurrentLanguageId(state.language)

    const currentJob = state.results.results.find((result) => result.id === id);

    if (!currentJob) {
        return
    }

    const statusToLowerCase = status.toLowerCase()
    const capitalizedStatus = statusToLowerCase.charAt(0).toUpperCase() + statusToLowerCase.slice(1)

    const message = {
        id,
        status: capitalizedStatus,
        name: currentJob.name,
        jobInformations: {
            extraField: [{
                languageId: currentLanguageCode,
                translation: note
            }],
        }
    };

    apiRequest(endpoint, 'PUT', state.user.jwt, message, {
        currentOrganisation: state.user.currentOrganisation?.id
    })
        .then((data: any) => {
            history.push(id.toString());
            dispatch({
                type: ResultsActions.UpdateResult,
                payload: {
                    result: data,
                },
            });
        })
        .catch((error: ApiError) => {
            Sentry.captureException(error)
            return dispatch({
                type: ResultsActions.AddError,
                payload: {
                    error: error.message,
                },
            });
        });
};

export type ResultChangeResultDetails = (
    id: string,
    name?: string,
    organisationUnit?: string,
    unit?: string,
    validatedProfile?: string,
    tags?: string[],
) => void;

export const resultChangeResultDetails = (
    id: string,
    name?: string,
    organisationUnit?: string,
    unit?: string,
    validatedProfile?: string,
    tags?: string[],
) => (dispatch: Dispatch) => {
    const state = store.getState();
    const endpoint = 'jobs';

    const currentJob = state.results.results.find((result) => result.id === id);
    const currentStatus = currentJob?.status.toLowerCase() || undefined;
    const capitalizedStatus = currentStatus ? (currentStatus.charAt(0).toUpperCase() + currentStatus.slice(1)) : undefined;

    const message = {
        id,
        name,
        organisationUnit,
        unitId: unit,
        validatedProfileId: validatedProfile,
        status: capitalizedStatus,
        tags,
    };

    apiRequest(endpoint, 'PUT', state.user.jwt, message, {
        currentOrganisation: state.user.currentOrganisation?.id
    })
        .then(() => {
            //@ts-ignore
            resultsGetResult(id)(dispatch);
        })
        .catch((error: ApiError) => {
            handleApiError(Domain.Results, ResultErrorTypes.changeResultDetails, state.language.currentLanguage)
            Sentry.captureException(error)
            return dispatch({
                type: ResultsActions.AddError,
                payload: {
                    error: error.message,
                },
            });
        });
};


export type ResultsDeleteResult = (id: string, history: History) => void;
export const resultsDeleteResult = (id: string, history: History) => async (
    dispatch: Dispatch
) => {
    const state = store.getState();
    const endpoint1 = `jobs/${id}`;

    await apiRequest(endpoint1, 'DELETE', state.user.jwt, undefined, {
        currentOrganisation: state.user.currentOrganisation?.id
    }).then(() => {
        resultsGetResults()(dispatch);
        history.push(Routes.ResultsRoute);
    }).catch((error: ApiError) => {
        Sentry.captureException(error)
        handleApiError(Domain.Results, ResultErrorTypes.deleteResult, state.language.currentLanguage)
    });
};


export type ResultsChangeResultValidatedProfile = (
    resultId: string,
    validatedProfileId: string
) => void;

export const resultsChangeResultValidatedProfile = (
    resultId: string,
    validatedProfileId: string
) => (dispatch: Dispatch) => {
    const state = store.getState();
    const endpoint = `jobs`;

    const message = {
        validatedProfileId: validatedProfileId,
        id: resultId,
    };

    apiRequest(endpoint, 'PUT', state.user.jwt, message, {
        currentOrganisation: state.user.currentOrganisation?.id
    })
        .then((data: any) => {
            dispatch({
                type: ResultsActions.UpdateResult,
                payload: {
                    result: data,
                },
            });
        })
        .catch((error: ApiError) => {
            Sentry.captureException(error)
            handleApiError(Domain.Results, ResultErrorTypes.changeResultValidatedProfile, state.language.currentLanguage)
            return dispatch({
                type: ResultsActions.AddError,
                payload: {
                    error: error.message,
                },
            });
        });
};

export type ResultsUpdateResultByField = (
    id: string,
    field: string,
    data: string,
    notTranslatable?: boolean,
    jobInformations?: boolean,
) => Promise<any>;

export const resultsUpdateResultByField = (
    id: string,
    field: string,
    data: string,
    notTranslatable?: boolean,
    jobInformations: boolean = true,
) => async (dispatch: Dispatch) => {
    const state = store.getState();

    const token = await getToken(state, dispatch);

    const currentLanguage = state.language.currentLanguage

    const endpoint = 'jobs';
    const currentLanguageCode = getCurrentLanguageId(state.language)
    const currentResult = state.results.results.find((result) => result.id === id);
    let message: any;

    if (jobInformations) {
        message = {
            id,
            jobInformations: {
                [field]: [{
                    languageId: currentLanguageCode,
                    translation: data
                }]
            }
        };
    } else {
        message = {
            id,
            [field]: [{
                languageId: currentLanguageCode,
                translation: data
            }]
        };
    }

    if (notTranslatable) {
        message.jobInformations = {
            [field]: data
        }
        message[field] = data
    }

    // Update the family specific info locally in the state, so that it is displayed in the job profiler 'normal view'.
    if (jobInformations) {
        dispatch({
            type: ResultsActions.UpdateResult,
            payload: {
                result: {
                    ...currentResult,
                    jobInformations: {
                        ...currentResult?.jobInformations,
                        [field]: {
                            //@ts-ignore
                            ...currentResult?.jobInformations[field],
                            [currentLanguage]: data
                        }
                    }
                },
            },
        });
    } else {
        dispatch({
            type: ResultsActions.UpdateResult,
            payload: {
                result: {
                    ...currentResult,
                    [field]: data
                },
            },
        });
    }
    return apiRequest(endpoint, 'PUT', token, message, {
        currentOrganisation: state.user.currentOrganisation?.id
    })
        .catch((error: ApiError) => {
            handleApiError(Domain.Results, ResultErrorTypes.updateResultByField, state.language.currentLanguage)
            Sentry.captureException(error)
            Sentry.captureException("Error updating result by field")
            dispatch({
                type: ResultsActions.AddError,
                payload: {
                    error: error.message,
                },
            });
            setTimeout(() => {
                dispatch({
                    type: ResultsActions.ClearErrors
                });
            }, 5000)
        });
};

export type ResultsUpdateResultExtraCompetence = (
    id: string,
    title: string,
    text: string,
) => void;

export const resultsUpdateResultExtraCompetence = (
    id: string,
    title: string,
    text: string,
) => (dispatch: Dispatch) => {
    const state = store.getState();
    const endpoint = 'jobs';
    const language = state.language.currentLanguage
    const getCompetences = state.results.results.find((result) => result.id === id)?.jobCompetences || []

    const newCompetence = {
        title: {
            [language]: title,
        },
        text: {
            [language]: text,
        },
    }


    const competences = [
        ...getCompetences.map(x => ({
            ...x,
            id: undefined
        })),
        newCompetence
    ]

    const message = {
        id,
        jobCompetences: competences.map(x => ({
            // @ts-ignore
            id: x.id || undefined,
            text: convertMultilingualStringToLanguageObject(state.language, x.text as MultiLingualString),
            title: convertMultilingualStringToLanguageObject(state.language, x.title as MultiLingualString)
        }))
    }

    return apiRequest(endpoint, 'PUT', state.user.jwt, message, {
        currentOrganisation: state.user.currentOrganisation?.id
    })
        .then((apiResponse: any) => {
            const currentResult = state.results.results.find((result) => result.id === id)
            if (!currentResult) {
                return
            }

            dispatch({
                type: ResultsActions.UpdateResult,
                payload: {
                    result: {
                        ...currentResult,
                        jobCompetences: apiResponse.jobCompetences
                    }
                },
            });
        })
        .catch((error: ApiError) => {
            handleApiError(Domain.Results, ResultErrorTypes.updateResultExtraCompetence, state.language.currentLanguage)
            Sentry.captureException(error)
            Sentry.captureException("Error updating job competences")
            dispatch({
                type: ResultsActions.AddError,
                payload: {
                    error: error.message,
                },
            });
            setTimeout(() => {
                dispatch({
                    type: ResultsActions.ClearErrors
                });
            }, 5000)
        });
};

export type ResultsChangeResultExtraCompetenceOrder = (
    id: string,
    oldIndex: number,
    newIndex: number
) => Promise<any>;

export const resultsChangeResultExtraCompetenceOrder = (
    id: string,
    oldIndex: number,
    newIndex: number
) => (dispatch: Dispatch) => {
    const state = store.getState();
    const getAllCompetences = state.results.results.find((result) => result.id === id)?.jobCompetences || [];

    // Move the item in the getAllCompetences array from the oldIndex to the newIndex
    getAllCompetences.splice(newIndex, 0, getAllCompetences.splice(oldIndex, 1)[0]);

    const message = {
        id,
        jobCompetences: getAllCompetences.map(x => ({
            text: convertMultilingualStringToLanguageObject(state.language, x.text),
            title: convertMultilingualStringToLanguageObject(state.language, x.title)
        }))
    }

    return apiRequest('jobs', 'PUT', state.user.jwt, message, {
        currentOrganisation: state.user.currentOrganisation?.id
    })
        .then(() => {
            dispatch({
                type: ResultsActions.UpdateResultExtraCompetence,
                payload: {
                    resultId: id,
                    jobCompetences: getAllCompetences
                },
            });
        })
        .catch((error: ApiError) => {
            handleApiError(Domain.Results, ResultErrorTypes.changeResultExtraCompetenceOrder, state.language.currentLanguage)
            return dispatch({
                type: ResultsActions.AddError,
                payload: {
                    error: error.message,
                },
            });
        });
};

export type ResultsChangeResultExtraCompetence = (
    id: string,
    title: string,
    text: string,
    position: number
) => Promise<any>;

export const resultsChangeResultExtraCompetence = (
    id: string,
    title: string,
    text: string,
    position: number,
) => (dispatch: Dispatch) => {
    const state = store.getState();
    const language = state.language.currentLanguage
    const getAllCompetences = state.results.results.find((result) => result.id === id)?.jobCompetences || [];

    const changeItem = {
        id,
        jobCompetences:
            getAllCompetences.map((x, index) => {
                if (index === position) {
                    return {
                        title: {
                            ...x.title,
                            [language]: title
                        },
                        text: {
                            ...x.text,
                            [language]: text
                        }
                    }
                }
                return x
            })
    }

    const message = changeItem

    dispatch({
        type: ResultsActions.UpdateResultExtraCompetence,
        payload: {
            resultId: id,
            jobCompetences: message.jobCompetences
        },
    });

    return Promise.resolve()
};

export type ResultsSaveExtraCompetence = (
    id: string,
    position: number,
    remove?: boolean
) => Promise<any>;

export const resultsSaveExtraCompetence = (
    id: string,
    position: number,
    remove?: boolean
) => (dispatch: Dispatch) => {
    const state = store.getState();
    const endpoint = 'jobs';
    const getAllCompetences = state.results.results.find((result) => result.id === id)?.jobCompetences || [];

    const changeItem = {
        id,
        jobCompetences: getAllCompetences.map(x => ({
            text: convertMultilingualStringToLanguageObject(state.language, x.text),
            title: convertMultilingualStringToLanguageObject(state.language, x.title)
        }))
    }

    const deleteItem = {
        id,
        jobCompetences: getAllCompetences.filter((_, index) => {
            return index !== position
        }).map(x => ({
            text: convertMultilingualStringToLanguageObject(state.language, x.text),
            title: convertMultilingualStringToLanguageObject(state.language, x.title)
        }))
    }

    const message = remove ? deleteItem : changeItem

    return apiRequest(endpoint, 'PUT', state.user.jwt, message, {
        currentOrganisation: state.user.currentOrganisation?.id
    })
        .then(() => {
            if (remove) {
                dispatch({
                    type: ResultsActions.RemoveExtraCompetence,
                    payload: {
                        resultId: id,
                        position
                    },
                });
            }
        })
        .catch((error: ApiError) => {
            handleApiError(Domain.Results, ResultErrorTypes.saveExtraCompetence, state.language.currentLanguage)
            return dispatch({
                type: ResultsActions.AddError,
                payload: {
                    error: error.message,
                },
            });
        });
};
